@use '@angular/material' as mat;

.howden-settings-menu {
  .greetings {
    text-align: center;
    padding: 0.5em;
    border-bottom-style: solid;
    border-bottom-width: 2px;

    span {
      font-size: 1.2em;
      font-weight: 700;
    }
  }
}

.userProfile {
  mat-icon {
    scale: 1.5;
  }
}

.light-theme {
  .userProfile {
    background-color: $howden-accent-dark-color !important;
  }

  .howden-settings-menu {
    .greetings {
      color: mat.m2-get-color-from-palette($howden-primary-p, 500);
      border-bottom-color: mat.m2-get-color-from-palette($howden-primary-p, 500);
    }
  }
}

.dark-theme {
  .userProfile {
    background-color: white !important;
  }
}