$themes: (
  light,
  dark
);
$breakpoint-xs-size: 600px;
$breakpoint-sm-size: 960px;
$breakpoint-md-size: 1280px;
$breakpoint-lg-size: 1920px;
$breakpoint-xl-size: 5000px;
$breakpoint-xs: 'xs';
$breakpoint-sm: 'sm';
$breakpoint-md: 'md';
$breakpoint-lg: 'lg';
$breakpoint-xl: 'xl';
$breakpoints: (
  $breakpoint-xs,
  $breakpoint-sm,
  $breakpoint-md,
  $breakpoint-lg,
  $breakpoint-xl
);
$breakpoints-size: (
  $breakpoint-xs: $breakpoint-xs-size,
  $breakpoint-sm: $breakpoint-sm-size,
  $breakpoint-md: $breakpoint-md-size,
  $breakpoint-lg: $breakpoint-lg-size,
  $breakpoint-xl: $breakpoint-xl-size,
);

:root {
  --breakpoint-xs-size: $breakpoint-xs-size;
  --breakpoint-sm-size: $breakpoint-sm-size;
  --breakpoint-md-size: $breakpoint-md-size;
  --breakpoint-lg-size: $breakpoint-lg-size;
  --breakpoint-xl-size: $breakpoint-xl-size;
}