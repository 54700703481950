@use '@angular/material' as mat;

// @import 'https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200&amp;v=1706862484832';

.material-symbols-outlined {
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
}

// https: //github.com/angular/components/blob/main/guides/duplicate-theming-styles.md
mat.$theme-ignore-duplication-warnings: true;

@include mat.typography-hierarchy($howden-typography-config);
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($howden-typography-config);`
@include mat.all-component-typographies($howden-typography-config);
@include mat.core();