@use '@angular/material' as mat;

.toast-container {
  pointer-events: all !important;

  .ngx-toastr {
    -webkit-box-shadow: 24px 28px 5px -8px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 24px 28px 5px -8px rgba(0, 0, 0, 0.14);
    box-shadow: 24px 28px 5px -8px rgba(0, 0, 0, 0.14);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;

    &.toast-warning {
      background-color: $howden-warning-color;
      color: mat.m2-get-color-from-palette($howden-warning-p, 50) !important;
    }

    &.toast-success {
      background-color: $howden-success-color;
      color: mat.m2-get-color-from-palette($howden-success-p, 50) !important;
    }

    &.toast-info {
      background-color: $howden-info-color;
      color: mat.m2-get-color-from-palette($howden-secondary-p, 900) !important;
    }

    &.toast-error {
      background-color: $howden-error-color;
      color: mat.m2-get-color-from-palette($howden-error-p, 50) !important;
    }
  }

  .snack-notification {
    font-weight: bold;
    margin: 1em;
    padding: 1em;
    -webkit-box-shadow: 24px 28px 5px -8px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 24px 28px 5px -8px rgba(0, 0, 0, 0.14);
    box-shadow: 24px 28px 5px -8px rgba(0, 0, 0, 0.14);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    max-width: 30em;

    .alert-title {
      font-size: 1.2em;
    }

    .header-icon mat-icon {
      transform: scale(1.6);
    }

    &.snack-notification--error {
      background-color: $howden-error-color;
      color: mat.m2-get-color-from-palette($howden-error-p, 50) !important;

      .error-stacktrace {
        margin-top: 20px;
      }

      .btn-container {
        --mdc-text-button-label-text-color: mat.m2-get-color-from-palette($howden-error-p, 50) !important;

        text-align: center;
        margin-top: 10px;
      }

      p {
        font-size: 1em;
      }
    }

    &.snack-notification--servererror {
      background-color: #fff;
      color: $howden-error-color;
      min-width: 20em;

      h2 {
        color: $howden-error-color;
      }

      .header-icon mat-icon {
        transform: scale(2);
      }

      .error-row {
        .error-field {
          span:first-of-type {
            margin-right: 1em;
            color: $howden-primary-light-color !important;
            font-weight: bold;
          }
        }
      }

      .mat-expansion-panel {
        background-color: $howden-error-color;

        .mat-expansion-panel-header-title {
          color: mat.m2-get-color-from-palette($howden-error-p, 50) !important;
          font-weight: bold;
        }

        .mat-expansion-indicator:after {
          color: mat.m2-get-color-from-palette($howden-error-p, 50) !important;
        }

        .mat-expansion-panel-body {
          color: #fff;
        }

        .validation-errors-headers {
          color: mat.m2-get-color-from-palette($howden-error-p, 50) !important;
          font-weight: bold;
          border-bottom: 1px solid mat.m2-get-color-from-palette($howden-error-p, 50) !important;
        }

        .validation-error-row {
          padding: 1em 0 1em 0;
        }
      }

      .btn-container {
        text-align: center;
        margin-top: 10px;

        button {
          color: $howden-primary-light-color !important;
          font-weight: 700;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-xs-size) {
  .toast-container {
    .snack-notification {
      max-width: 87vw;
    }

    &.snack-notification--servererror {
      max-width: 87vw;
    }
  }
}