@use '@angular/material' as mat;

$howden-warning-color: mat.m2-get-color-from-palette($howden-warning-p, 500);
$howden-success-color: mat.m2-get-color-from-palette($howden-success-p, 500);
$howden-error-color: mat.m2-get-color-from-palette($howden-error-p, 500);
$howden-info-color: mat.m2-get-color-from-palette($howden-information-p, 500);
$howden-filler-color: mat.m2-get-color-from-palette($howden-filler-p, 500);

//Light theme colors
$howden-primary-light-color: mat.m2-get-color-from-palette($howden-primary-p, 500);
$howden-secondary-light-color: mat.m2-get-color-from-palette($howden-secondary-p, 300);
$howden-highlight-light-color: mat.m2-get-color-from-palette($howden-primary-p, 800);
$howden-accent-light-color: mat.m2-get-color-from-palette($howden-accent-p, 500);

//Dark theme colors
$howden-primary-dark-color: mat.m2-get-color-from-palette($howden-primary-p, 100);
$howden-secondary-dark-color: mat.m2-get-color-from-palette($howden-warning-p, 500);
$howden-highlight-dark-color: mat.m2-get-color-from-palette($howden-secondary-p, 500);
$howden-accent-dark-color: mat.m2-get-color-from-palette($howden-accent-p, 500);

$dark-text: mat.m2-get-color-from-palette($howden-primary-p, 800);
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dividers-dark: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$light-background: mat.m2-get-color-from-palette($howden-white-p, 500);
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$light-tooltip-color: mat.m2-get-color-from-palette($howden-secondary-p, 900);
$dark-bg-tooltip: lighten($light-tooltip-color, 20%);
$dark-bg-alpha-4: rgba($light-tooltip-color, 0.04);
$dark-bg-alpha-12: rgba($light-tooltip-color, 0.12);

$light-text: mat.m2-get-color-from-palette($howden-white-p, 500);
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$dividers-light: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$dark-background: mat.m2-get-color-from-palette($howden-grey-p, 900);
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(mat.m2-get-color-from-palette($howden-primary-p, 50), 0.04);
$light-bg-alpha-12: rgba(mat.m2-get-color-from-palette($howden-primary-p, 50), 0.12);

.color-error {
  color: $howden-error-color !important;
}

.color-warning {
  color: $howden-warning-color !important;
}

.color-success {
  color: $howden-success-color !important;
}

.color-info {
  color: $howden-info-color !important;
}

.bgcolor-error {
  background-color: $howden-error-color !important;
}

.bgcolor-warning {
  background-color: $howden-warning-color !important;
}

.bgcolor-success {
  background-color: $howden-success-color !important;
}

.bgcolor-info {
  background-color: $howden-info-color !important;
}

/* EXTRA MAT COLORS */
.dark-theme {

  howden-table {

    .mat-success.mat-mdc-icon-button,
    .mat-success.mat-icon {
      color: white !important;
    }

    mat-row:not(.highlight) {
      .mat-success:not(.mat-mdc-icon-button):not(.mat-icon) {
        color: #fff !important;
        background-color: white !important;
      }

      .mat-success.mat-mdc-icon-button,
      .mat-success.mat-icon {
        color: white !important;
      }

      .mat-cancel:not(.mat-mdc-icon-button):not(.mat-icon) {
        color: #fff !important;
        background-color: white !important;
      }

      .mat-cancel.mat-mdc-icon-button,
      .mat-cancel.mat-icon {
        color: white !important;
      }
    }

    mat-row.highlight {
      .mat-success:not(.mat-mdc-icon-button):not(.mat-icon) {
        color: black !important;
        background-color: black !important;
      }

      .mat-success.mat-mdc-icon-button,
      .mat-success.mat-icon {
        color: black !important;
      }

      .mat-cancel:not(.mat-mdc-icon-button):not(.mat-icon) {
        color: black !important;
        background-color: black !important;
      }

      .mat-cancel.mat-mdc-icon-button,
      .mat-cancel.mat-icon {
        color: black !important;
      }
    }
  }
}

.mat-success:not(.mat-mdc-icon-button):not(.mat-icon) {
  color: #fff !important;
  background-color: $howden-success-color !important;
}

.mat-success.mat-mdc-icon-button,
.mat-success.mat-icon {
  color: $howden-success-color !important;
}

.mat-info:not(.mat-mdc-icon-button):not(.mat-icon) {
  color: #fff !important;
  background-color: $howden-info-color !important;
}

.mat-info.mat-mdc-icon-button,
.mat-info.mat-icon {
  color: $howden-info-color !important;
}

.mat-caution:not(.mat-mdc-icon-button):not(.mat-icon) {
  color: #000 !important;
  background-color: $howden-warning-color !important;
}

.mat-caution.mat-mdc-icon-button,
.mat-caution.mat-icon {
  color: $howden-warning-color !important;
}

.mat-cancel:not(.mat-mdc-icon-button):not(.mat-icon) {
  color: #fff !important;
  background-color: $howden-error-color !important;
}

.mat-cancel.mat-mdc-icon-button,
.mat-cancel.mat-icon {
  color: $howden-error-color !important;
}

.mat-filler:not(.mat-mdc-icon-button):not(.mat-icon) {
  color: #fff !important;
  background-color: $howden-filler-color !important;
}

.mat-filler.mat-mdc-icon-button,
.mat-filler.mat-icon {
  color: $howden-filler-color !important;
}

/* end EXTRA MAT COLORS */

//Variables de color que se utilizan con var(--xxxx)
:root {
  --howden-primary-light-color: $howden-primary-light-color;
  --howden-secondary-light-color: $howden-secondary-light-color;
  --howden-highlight-light-color: $howden-highlight-light-color;
  --howden-accent-light-color: $howden-accent-light-color;
  --howden-primary-dark-color: $howden-primary-dark-color;
  --howden-secondary-dark-color: $howden-secondary-dark-color;
  --howden-highlight-dark-color: $howden-highlight-dark-color;
  --howden-accent-dark-color: $howden-accent-dark-color;
  --light-text: $light-text;
  --light-primary-text: $light-primary-text;
  --light-accent-text: $light-accent-text;
  --dark-text: $dark-text;
  --dark-primary-text: $dark-primary-text;
  --dark-accent-text: $dark-accent-text;
  --dark-background: $dark-background;
  --light-background: $light-background;
  --howden-warning-color: $howden-warning-color;
  --howden-success-color: $howden-success-color;
  --howden-cancel-color: $howden-error-color;
  --howden-info-color: $howden-info-color;
  --howden-filler-color: $howden-filler-color;
}