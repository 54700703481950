@use '@angular/material' as mat;

howden-subtoolbar {
  .breadcrumb-container {
    padding-left: 2em;

    li {
      display: inline-block;
      font-size: 1.1;

      .mat-icon {
        display: inline-block;
        position: relative;
        top: 8px;
      }

      a {
        text-decoration: none;
      }
    }
  }

  .notification-container {
    margin-right: 1em;
    margin-left: 3em;

    .notification-btn {
      &.notification-btn-100 .mat-badge-content {
        font-size: 9px;
      }
    }
  }

  .mat-mdc-form-field {
    font-size: 0.9em;
  }
}

.dark-theme {
  howden-subtoolbar {
    div {
      background-color: $dark-background;
    }

    .breadcrumb-container {
      li {
        color: $howden-primary-dark-color;
      }
    }

    .notification-container {
      .notification-btn {
        background-color: mat.m2-get-color-from-palette($howden-secondary-p, 500);
        color: #fff;
      }
    }
  }
}

.light-theme {
  howden-subtoolbar {
    .breadcrumb-container {
      li {
        color: $howden-primary-light-color;
      }
    }

    .notification-container {
      .notification-btn {
        background-color: mat.m2-get-color-from-palette($howden-secondary-p, 500);
        color: #fff;
      }
    }
  }
}