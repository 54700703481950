.mat-mdc-chip {
  &.alert {
    opacity: 1 !important;

    &.ok {
      background-color: $howden-success-color;
      border-color: $howden-success-color;
    }

    &.info {
      background-color: $howden-info-color;
      border-color: $howden-info-color;
    }

    &.warning {
      background-color: $howden-warning-color;
      border-color: $howden-warning-color;
    }

    &.error {
      background-color: $howden-error-color;
      border-color: $howden-error-color;
    }
  }
}


.light-theme {
  .mat-mdc-chip {
    &.alert {
      color: $howden-primary-light-color;
    }
  }
}

.dark-theme {
  .mat-mdc-chip {
    &.alert {
      color: $howden-primary-light-color;
    }
  }
}