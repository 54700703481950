@use 'sass:math';
@use 'sass:list';
@use 'sass:map';

//////////////////////////////////////////////////////////////////////////////
// Layout classes
//////////////////////////////////////////////////////////////////////////////
.main-layout {
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 1fr;
  grid-template-areas:
    "header"
    "content"
    "footer";
  height: 100vh;
  overflow: hidden;

  .howden-header {
    grid-area: header;
  }

  howden-sidemenu {
    grid-area: content;
    overflow: hidden;

    mat-sidenav-container {
      overflow: hidden;
      height: 100%;

      mat-sidenav {
        mat-nav-list {
          margin-left: 10px;
        }
      }

      mat-sidenav-content {
        overflow: hidden;
        height: 100%;
      }
    }
  }

  main {
    height: 100%;
    overflow-y: auto;
    padding: 0 1em 0 1em;
  }

  howden-footer {
    grid-area: footer;
  }

  &.no-menu {
    main {
      padding: 0 1em;
    }
  }
}

@media (max-width: $breakpoint-sm-size) {
  .main-layout {
    display: grid;
    grid-template-rows: auto 1fr;
    grid-template-columns: 1fr;
    grid-gap: calc(1em / 2);
    grid-template-areas:
      "header"
      "content";
    height: 100vh;
    overflow: hidden;

    howden-subtoolbar {
      div {
        padding-left: calc(1em / 2);

        howden-breadcrumb {
          display: none;
        }
      }
    }

    howden-footer {
      display: none;
    }
  }
}

[center],
.center {
  margin: 0 auto;
}

form {
  &.full-width {
    width: 100%;

    .mat-mdc-form-field {
      &.full-width {
        width: 100%;
      }
    }
  }
}

.float-right {
  float: right;

  &::after {
    clear: both;
  }
}

.clearfix {
  clear: both;
}

//////////////////////////////////////////////////////////////////////////////
/// Flexbox
//////////////////////////////////////////////////////////////////////////////
$sizeunits: (
  0,
  1,
  2,
  3,
  4,
  5
);
$basisunits: (
  0,
  auto,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12
);
$gaps: (
  5px,
  10px,
  25px,
  50px,
  100px
);

.flex {
  display: flex !important;
}

.flex-inline {
  display: inline-flex !important;
}

.flex,
.flex-inline {
  box-sizing: border-box;

  /* #region  Flex direction */
  &.f-fd--row {
    flex-direction: row;
  }

  /* Default */
  &.f-fd--rowreverse {
    flex-direction: row-reverse;
  }

  &.f-fd--column {
    flex-direction: column;
  }

  &.f-fd--column-reverse {
    flex-direction: column-reverse;
  }

  /* #endregion */

  /* #region  Flex Wrap */
  &.f-w--nowrap {
    flex-wrap: nowrap;
  }

  /* Default */
  &.f-w--wrap {
    flex-wrap: wrap;
  }

  &.f-w--wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  /* #endregion */

  /* #region  Justify Content */
  &.f-jc--end {
    justify-content: flex-end;
  }

  &.f-jc--start {
    justify-content: flex-start;
  }

  /* Default */
  &.f-jc--center {
    justify-content: center;
  }

  &.f-jc--spacearound {
    justify-content: space-around;
  }

  &.f-jc--spacebetween {
    justify-content: space-between;
  }

  /* #endregion */

  /* #region  Align Items */
  &.f-ai--start {
    align-items: flex-start;
  }

  &.f-ai--center {
    align-items: center;
  }

  &.f-ai--end {
    align-items: flex-end;
  }

  &.f-ai--stretch {
    align-items: stretch;
  }

  /* Default */
  &.f-ai--baseline {
    align-items: baseline;
  }

  /* #endregion */

  /* #region  Gap */
  @each $gap in $gaps {
    &.f-gap--#{$gap} {
      gap: $gap;
    }

    &.f-row-gap--#{$gap} {
      row-gap: $gap;
    }

    &.f-col-gap--#{$gap} {
      column-gap: $gap;
    }
  }

  /* #endregion */

  @each $units in $sizeunits {
    &>.f-grow--#{$units} {
      flex-grow: #{$units};
    }

    &>.f-shrink--#{$units} {
      flex-shrink: #{$units};
    }
  }

  @each $units in $basisunits {
    @if ($units ==0 or $units ==auto) {
      &>.f-basis--#{$units} {
        flex-basis: #{$units};
      }
    }

    @else {
      &>.f-basis--#{$units} {
        $percetage: math.div($units, 12);
        flex-basis: math.percentage($percetage);
      }
    }
  }

  /* #region  Align self */
  &>.f-as--auto {
    align-self: auto;
  }

  /* Default */
  &>.f-as--start {
    align-self: flex-start;
  }

  &>.f-as--center {
    align-self: center;
  }

  &>.f-as--end {
    align-self: flex-end;
  }

  &>.f-as--stretch {
    align-self: stretch;
  }

  &>.f-as--stretch {
    align-self: baseline;
  }

  /* #endregion */

  @each $breakpoint in $breakpoints {
    $lt: map.get($breakpoints-size, $breakpoint);

    @media screen and (max-width: $lt) {

      /* #region  Flex direction */
      &.f-fd--row-#{$breakpoint} {
        flex-direction: row;
      }

      /* Default */
      &.f-fd--rowreverse-#{$breakpoint} {
        flex-direction: row-reverse;
      }

      &.f-fd--column-#{$breakpoint} {
        flex-direction: column;
      }

      &.f-fd--column-reverse-#{$breakpoint} {
        flex-direction: column-reverse;
      }

      /* #endregion */

      /* #region  Flex wrap */
      &.f-w--nowrap-#{$breakpoint} {
        flex-wrap: nowrap;
      }

      /* Default */
      &.f-w--wrap-#{$breakpoint} {
        flex-wrap: wrap;
      }

      &.f-w--wrap-reverse-#{$breakpoint} {
        flex-wrap: wrap-reverse;
      }

      /* #endregion */

      /* #region  Justify content */
      &.f-jc--end-#{$breakpoint} {
        justify-content: flex-end;
      }

      &.f-jc--start-#{$breakpoint} {
        justify-content: flex-start;
      }

      /* Default */
      &.f-jc--center-#{$breakpoint} {
        justify-content: center;
      }

      &.f-jc--spacearound-#{$breakpoint} {
        justify-content: space-around;
      }

      &.f-jc--spacebetween-#{$breakpoint} {
        justify-content: space-between;
      }

      /* #endregion */

      /* #region  Align items */
      &.f-ai--start-#{$breakpoint} {
        align-items: flex-start;
      }

      &.f-ai--center-#{$breakpoint} {
        align-items: center;
      }

      &.f-ai--end-#{$breakpoint} {
        align-items: flex-end;
      }

      &.f-ai--stretch-#{$breakpoint} {
        align-items: stretch;
      }

      /* Default */
      &.f-ai--baseline-#{$breakpoint} {
        align-items: baseline;
      }

      /* #endregion */

      /* #region  gap */
      @each $gap in $gaps {
        &.f-gap--#{$gap}-#{$breakpoint} {
          gap: $gap;
        }

        &.f-row-gap--#{$gap}-#{$breakpoint} {
          row-gap: $gap;
        }

        &.f-col-gap--#{$gap}-#{$breakpoint} {
          column-gap: $gap;
        }
      }

      /* #endregion */

      @each $units in $sizeunits {
        &>.f-grow--#{$units}-#{$breakpoint} {
          flex-grow: #{$units};
        }

        &>.f-shrink--#{$units}-#{$breakpoint} {
          flex-shrink: #{$units};
        }
      }

      @each $units in $basisunits {
        @if ($units ==0 or $units ==auto) {
          &>.f-basis--#{$units}-#{$breakpoint} {
            flex-basis: #{$units};
          }
        }

        @else {
          &>.f-basis--#{$units}-#{$breakpoint} {
            $percetage: math.div($units, 12);
            flex-basis: math.percentage($percetage);
          }
        }
      }

      /* #region  Align self */
      &>.f-as--auto-#{$breakpoint} {
        align-self: auto;
      }

      /* Default */
      &>.f-as--start-#{$breakpoint} {
        align-self: flex-start;
      }

      &>.f-as--center-#{$breakpoint} {
        align-self: center;
      }

      &>.f-as--end-#{$breakpoint} {
        align-self: flex-end;
      }

      &>.f-as--stretch-#{$breakpoint} {
        align-self: stretch;
      }

      &>.f-as--stretch-#{$breakpoint} {
        align-self: baseline;
      }

      /* #endregion */
    }
  }
}