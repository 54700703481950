@use '@angular/material' as mat;

.error-page {
  height: 100vh;
  width: 100vw;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 10em;
    line-height: 1em;
  }

  .error-text {
    max-width: 20vw;
    padding-left: 3em;

    h2 {
      font-size: 3em;
      line-height: 1em;
    }

    p {
      font-size: 2em;
      line-height: 1em;
    }
  }
}

.dark-theme {
  .error-page {

    h1,
    h2 {
      color: white !important;
    }

    div {
      &.error-code {
        h1 {
          color: white;
        }
      }
    }

    div {
      &.error-text {
        border-left: 1px solid #fff;

        h2 {
          color: mat.m2-get-color-from-palette($howden-accent-p, 500) !important;
        }
      }

      p {
        color: white;
        font-size: 1rem;

        a {
          color: mat.m2-get-color-from-palette($howden-accent-p, 500) !important;
        }
      }
    }
  }
}

.light-theme {
  .error-page {
    h1 {
      color: $howden-highlight-light-color;
    }

    .error-text {
      border-left: 1px solid $howden-secondary-light-color;

      p {
        color: $howden-secondary-light-color;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-md-size) {
  .error-page {
    h1 {
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .error-text {
      max-width: 80vw;

      h2 {
        text-align: center;
      }

      p {
        text-align: center;
      }
    }
  }

  .dark-theme {
    .error-page {
      .error-text {
        border-left: none;
        border-top: 1px solid #fff;
      }
    }
  }

  .light-theme {
    .error-page {
      .error-text {
        border-left: none;
        border-top: 1px solid $howden-secondary-light-color;
      }
    }
  }
}

@media screen and (max-width: $breakpoint-sm-size) {
  .error-page {
    h1 {
      font-size: 6em;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    .error-text {
      max-width: 80vw;
      padding-left: 0;

      h2 {
        font-size: 2em;
        text-align: center;
      }

      p {
        font-size: 1em;
        text-align: center;
      }
    }
  }
}