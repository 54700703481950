dl {
  &.dl-horizontal {
    width: 100%;
    overflow: hidden;
    padding: 0;
    margin: 0;
    line-height: 1.5;
    font-size: 1em;

    dt {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-weight: 700;

      @media (min-width: $breakpoint-xs-size) {
        float: left;
        width: 140px;
        clear: left;
      }
    }

    dd {
      margin-left: 0;
      margin-bottom: 0.5rem;

      @media (min-width: $breakpoint-xs-size) {
        margin-left: 160px;
      }
    }
  }
}

ul,
ol {
  list-style-type: none;
}