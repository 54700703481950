@use '@angular/material' as mat;

.mat-toolbar {
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: left;

    img {
      &.primary-logo {
        width: 160px;
        margin-top: 10px;
      }
    }
  }

  .user-settings-container {
    display: flex;
    align-items: center;
    justify-content: left;

    img {
      &.secondary-logo {
        max-height: 36px;
        margin-right: 1em;
      }
    }
  }


  .toolbar-title {
    text-align: center;

    h1 {
      @include mat.m2-typography-level($howden-typography-config, 'headline-4');
      color: mat.m2-get-color-from-palette($howden-accent-p, 500);

      &.staging {
        color: $howden-warning-color;
      }

      &.develop {
        color: $howden-error-color;
      }
    }
  }
}

.dark-theme {
  .mat-toolbar {
    background: $dark-background;
  }

  .icon-toolbar {
    color: $howden-primary-light-color;
  }

  .hamburger-icon {
    color: mat.m2-get-color-from-palette($howden-accent-p, 500);
  }
}

.light-theme {
  .mat-toolbar {
    background: $howden-primary-light-color !important;
  }

  .icon-toolbar {
    color: $howden-primary-light-color !important;
  }

  .hamburger-icon {
    color: mat.m2-get-color-from-palette($howden-accent-p, 500);
  }
}

@media (max-width: $breakpoint-sm-size) {
  .toolbar-title {
    display: none;
  }

  .logo-container {
    .secondary-logo {
      display: none;
    }
  }
}