.btn-inline {
  margin-top: 1em !important;
}

button>img {
  width: 16px;
  margin-right: 5px;
  margin-bottom: -3px;
  /* display: inline-block; */
}

button[disabled] {
  opacity: 0.5;
  cursor: pointer;
}