$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100);

@mixin generate-padding-bottom() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .padding-bottom-#{$space} {
        padding-bottom: #{$space} !important;
      }
    } @else {
      .padding-bottom-#{$space} {
        padding-bottom: #{$space}px !important;
      }
    }
  }
}

@mixin generate-padding-top() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .padding-top-#{$space} {
        padding-top: #{$space} !important;
      }
    } @else {
      .padding-top-#{$space} {
        padding-top: #{$space}px !important;
      }
    }
  }
}

@mixin generate-padding-right() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .padding-right-#{$space} {
        padding-right: #{$space} !important;
      }
    } @else {
      .padding-right-#{$space} {
        padding-right: #{$space}px !important;
      }
    }
  }
}

@mixin generate-padding-left() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .padding-left-#{$space} {
        padding-left: #{$space} !important;
      }
    } @else {
      .padding-left-#{$space} {
        padding-left: #{$space}px !important;
      }
    }
  }
}

@mixin generate-padding() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .padding-#{$space} {
        padding: #{$space} #{$space} #{$space} #{$space} !important;
      }
    } @else {
      .padding-#{$space} {
        padding: #{$space}px #{$space}px #{$space}px #{$space}px !important;
      }
    }
  }
}

@include generate-padding-bottom();
@include generate-padding-top();
@include generate-padding-right();
@include generate-padding-left();
@include generate-padding();
