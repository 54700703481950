.hidden {
  visibility: hidden !important;
}

.none {
  display: none !important;
}

.hide-overflow-x {
  overflow-x: hidden !important;
}

.hide-overflow-y {
  overflow-y: hidden !important;
}

.hide-md {
  @media (max-width: $breakpoint-md-size) {
    display: none !important;
  }
}

.hide-lg {
  @media (min-width: $breakpoint-md-size) {
    display: none !important;
  }
}
