@use '@angular/material' as mat;

//////////////////////////////////////////////////////////////////////////////
/// Font faces
//////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: 'GT Ultra';
  src: url('../../assets/fonts/gt-ultra/GT-Ultra-Median-Regular.woff2') format('woff2'),
    url('../../assets/fonts/gt-ultra/GT-Ultra-Median-Regular.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/gt-ultra/GT-Ultra-Median-Regular.woff2') format('woff2'),
    url('../../assets/fonts/gt-ultra/GT-Ultra-Median-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 100;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Hair.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Hair.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Hair.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Hair.woff') format('woff');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 200;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Th.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Th.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Th.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Th.woff') format('woff');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 300;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Lt.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Lt.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Lt.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Lt.woff') format('woff');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 400;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Rg.woff') format('woff')
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 500;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Md.woff') format('woff')
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 600;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_SBd.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_SBd.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_SBd.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_SBd.woff') format('woff')
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 700;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Bd.woff') format('woff')
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 800;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Blk.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Blk.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Blk.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_Blk.woff') format('woff');
}

@font-face {
  font-family: 'Aktiv Grotesk';
  font-weight: 900;
  src: url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_XBd.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_XBd.woff') format('woff');
  src: local(''),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_XBd.woff2') format('woff2'),
    url('../../assets/fonts/aktiv-grotesk/AktivGrotesk_W_XBd.woff') format('woff');
}

@font-face {
  font-family: 'Material Symbols Outlined';
  font-style: normal;
  font-weight: 100 700;
  src: url('../../assets/fonts/material-symbols-outlined/material-symbols-outlined.woff2') format('woff2');
}

$howden-primary-font-family: 'Aktiv Grotesk, Arial, sans-serif';
$howden-secondary-font-family: 'GT Ultra, Georgia, sans-serif';

$howden-typography-config: mat.m2-define-typography-config($font-family: $howden-primary-font-family,
    $headline-1: mat.m2-define-typography-level(112px, 112px, 300, $font-family: $howden-secondary-font-family, $letter-spacing: -0.05em),
    $headline-2: mat.m2-define-typography-level(56px, 56px, 400, $font-family: $howden-secondary-font-family, $letter-spacing: -0.02em),
    $headline-3: mat.m2-define-typography-level(45px, 48px, 400, $font-family: $howden-secondary-font-family, $letter-spacing: -0.005em),
    $headline-4: mat.m2-define-typography-level(34px, 40px, 400, $font-family: $howden-secondary-font-family),
    $headline-5: mat.m2-define-typography-level(24px, 32px, 400, $font-family: $howden-secondary-font-family),
    $headline-6: mat.m2-define-typography-level(20px, 32px, 400, $font-family: $howden-secondary-font-family),
    $subtitle-1: mat.m2-define-typography-level(16px, 28px, 400, $font-family: $howden-secondary-font-family),
    $subtitle-2: mat.m2-define-typography-level(14px, 20px, 500, $font-family: $howden-primary-font-family),
    $body-1: mat.m2-define-typography-level(14px, 20px, 400, $font-family: $howden-primary-font-family),
    $body-2: mat.m2-define-typography-level(14px, 20px, 500, $font-family: $howden-primary-font-family),
    $caption: mat.m2-define-typography-level(12px, 20px, 400, $font-family: $howden-primary-font-family),
    $button: mat.m2-define-typography-level(14px, 14px, 500, $font-family: $howden-primary-font-family));

body {
  @include mat.m2-typography-level($howden-typography-config, 'body-1');
}

input,
select {
  @include mat.m2-typography-level($howden-typography-config, 'body-1');
}

h1 {
  @include mat.m2-typography-level($howden-typography-config, 'headline-5');
}

h2 {
  @include mat.m2-typography-level($howden-typography-config, 'headline-6');
}

h3 {
  @include mat.m2-typography-level($howden-typography-config, 'subtitle-1');
}

h4 {
  @include mat.m2-typography-level($howden-typography-config, 'subtitle-1');
}

.page-title {
  @include mat.m2-typography-level($howden-typography-config, 'headline-5');
}

.title {
  margin-top: 0;
}

p {
  font-size: smaller;
}

label {
  font-size: smaller;
}

.ellipsis-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.material-symbols-outlined {
  font-family: 'Material Symbols Outlined';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -moz-font-feature-settings: 'liga';
  -moz-osx-font-smoothing: grayscale;
}