.info-cards {
  display: flex;
  justify-content: space-between;
}

howden-ask-for-data {

  mat-card {
    width: 100%;
  }
}

howden-ask-for-checks {

  mat-card {
    width: 100%;
  }
}

howden-ask-for-radio {

  mat-card {
    width: 100%;
  }
}

howden-ask-for-select {

  mat-card {
    width: 100%;
  }
}

mat-card {
  flex-grow: 1;
  margin: 0 1vw;

  mat-card-content {
    color: rgba(255, 255, 255, 0.9);

    .title {
      font-weight: 600;
      text-transform: uppercase;
    }

    .value {
      margin-top: 0.3em;
      font-size: 50px;
    }
  }
}