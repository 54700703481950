@use '@angular/material' as mat;

/* Para generar la paleta de colores => http://mcg.mbitson.com/#!?mcgpalette0=%23173f35 */

$howden-primary-p: mat.m2-define-palette((50 : #e3e8e7,
      100 : #b9c5c2,
      200 : #8b9f9a,
      300 : #5d7972,
      400 : #3a5c53,
      500 : #173f35,
      600 : #143930,
      700 : #113128,
      800 : #0d2922,
      900 : #071b16,
      A100 : #5bffcd,
      A200 : #28ffbd,
      A400 : #00f4a9,
      A700 : #00da97,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-secondary-p: mat.m2-define-palette((50 : #e1ebf8,
      100 : #b5cded,
      200 : #84abe1,
      300 : #5289d5,
      400 : #2d70cc,
      500 : #0857c3,
      600 : #074fbd,
      700 : #0646b5,
      800 : #043cae,
      900 : #022ca1,
      A100 : #cbd5ff,
      A200 : #98acff,
      A400 : #6583ff,
      A700 : #4c6fff,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #ffffff)));

$howden-accent-p: mat.m2-define-palette((50 : #fbfdf1,
      100 : #f6f9dc,
      200 : #f0f6c4,
      300 : #e9f2ac,
      400 : #e5ef9b,
      500 : #e0ec89,
      600 : #dcea81,
      700 : #d8e776,
      800 : #d3e46c,
      900 : #cbdf59,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #faffde,
      A700 : #f6ffc4,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-filler-p: mat.m2-define-palette((50 : #f1eee4,
      100 : #ddd5bc,
      200 : #c6ba8f,
      300 : #af9e62,
      400 : #9e8940,
      500 : #8d741e,
      600 : #856c1a,
      700 : #7a6116,
      800 : #705712,
      900 : #5d440a,
      A100 : #ffda90,
      A200 : #ffc85d,
      A400 : #ffb72a,
      A700 : #ffaf11,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-success-p: mat.m2-define-palette((50 : #e2f4e2,
      100 : #b6e3b6,
      200 : #86d186,
      300 : #56be56,
      400 : #31b031,
      500 : #0da20d,
      600 : #0b9a0b,
      700 : #099009,
      800 : #078607,
      900 : #037503,
      A100 : #a3ffa3,
      A200 : #70ff70,
      A400 : #3dff3d,
      A700 : #24ff24,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-warning-p: mat.m2-define-palette((50 : #fef5e0,
      100 : #fee6b3,
      200 : #fdd580,
      300 : #fcc44d,
      400 : #fbb726,
      500 : #faaa00,
      600 : #f9a300,
      700 : #f99900,
      800 : #f89000,
      900 : #f67f00,
      A100 : #ffffff,
      A200 : #fff4ea,
      A400 : #ffd8b7,
      A700 : #ffca9d,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-secondary-warning-p: mat.m2-define-palette((50 : #fee8e7,
      100 : #fdc6c4,
      200 : #fca19d,
      300 : #fb7b75,
      400 : #fa5e58,
      500 : #f9423a,
      600 : #f83c34,
      700 : #f7332c,
      800 : #f62b25,
      900 : #f51d18,
      A100 : #ffffff,
      A200 : #fff3f3,
      A400 : #ffc1c0,
      A700 : #ffa8a7,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-error-p: mat.m2-define-palette((50 : #fbe0e2,
      100 : #f5b3b7,
      200 : #ee8087,
      300 : #e74d57,
      400 : #e12633,
      500 : #dc000f,
      600 : #d8000d,
      700 : #d3000b,
      800 : #ce0008,
      900 : #c50004,
      A100 : #ffeded,
      A200 : #ffbaba,
      A400 : #ff8787,
      A700 : #ff6e6e,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-information-p: mat.m2-define-palette((50 : #effbfa,
      100 : #d8f6f2,
      200 : #bef0e9,
      300 : #a3e9e0,
      400 : #90e5da,
      500 : #7ce0d3,
      600 : #74dcce,
      700 : #69d8c8,
      800 : #5fd3c2,
      900 : #4ccbb7,
      A100 : #ffffff,
      A200 : #f3fffd,
      A400 : #c0fff5,
      A700 : #a7fff1,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
      )));

$howden-white-p: mat.m2-define-palette((50 : #ffffff,
      100 : #ffffff,
      200 : #ffffff,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #ffffff,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000)));

$howden-grey-p: mat.m2-define-palette((50 : #e8e8e8,
      100 : #c5c5c5,
      200 : #9e9e9e,
      300 : #777777,
      400 : #5a5a5a,
      500 : #3d3d3d,
      600 : #373737,
      700 : #2f2f2f,
      800 : #272727,
      900 : #1a1a1a,
      A100 : #f07474,
      A200 : #ea4646,
      A400 : #fd0000,
      A700 : #e40000,
      contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff)));