@use '@angular/material' as mat;

$howden-dark-theme: mat.m2-define-dark-theme((
  color: (
    primary: $howden-accent-p,
    accent: $howden-information-p,
    warn: $howden-secondary-warning-p
  ),
  density: 0
));

.dark-theme {
  @include mat.all-component-themes($howden-dark-theme);
}

body.dark-theme {
  background-color: $dark-background;
  color: $light-primary-text;

  // main {
  //   background-color: mat.m2-get-color-from-palette($howden-grey-p, 600);
  // }

  .mat-mdc-tooltip-surface {
    background-color: $howden-filler-color;
  }

  .mat-mdc-form-field:not(.mat-form-field-invalid).mat-focused .mat-mdc-form-field-required-marker {
    color: mat.m2-get-color-from-palette($howden-accent-p, 500) !important;
  }

  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: $dividers-dark;
  }

  ::-webkit-scrollbar {
    width: 10px;
    background-color: $dividers-dark;
    border-radius: 7px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $howden-primary-dark-color;
    border: 1px solid $howden-primary-dark-color;
    border-radius: 7px;
  }
}
