howden-radiobutton-list {
  .howden-radiocontainer {
    position: relative;
    text-align: left;
    font-weight: 400;
    line-height: 1.125;
    letter-spacing: normal;
    margin-bottom: 0.6em;

    .radiogroup-title {
      margin-bottom: 1em;
      position: relative;
      top: -0.8em;
      font-size: 1.2em;
    }

    .mat-mdc-radio-group {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;

      .mat-mdc-radio-button {
        flex-grow: 1;
      }
    }
  }
}

.dark-theme {
  .howden-radiocontainer {
    .radiogroup-title {
      color: $howden-secondary-dark-color;
    }
  }
}

.light-theme {
  .howden-radiocontainer {
    .radiogroup-title {
      color: $howden-secondary-light-color;
    }
  }
}