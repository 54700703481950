//////////////////////////////////////////////////////////////////////////////
// Tabs
//////////////////////////////////////////////////////////////////////////////

.tabs-centered {

  /*TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version.*/
  .mat-tab-labels {
    justify-content: center;
  }

  &.no-border {
    .mat-mdc-tab-header {
      margin: 0 auto;
    }
  }
}

.mat-mdc-tab-body-content {
  overflow-x: hidden !important;
}