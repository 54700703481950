@use '@angular/material' as mat;

// estylos pares - impares

/*.mat-row:nth-child(even){
  background: rgb(255, 255, 255);
}*/
/*.mat-row:nth-child(odd){
  background: $light-color;
}*/

.light-theme {
  // .table-container {
  //   @include theming.mat-elevation(8);
  // }

  // mat-paginator {
  //   @include theming.mat-elevation(8);
  // }

  .mat-mdc-header-row>.mat-mdc-header-cell {
    color: $howden-primary-light-color;
    font-weight: 500;

    &.contrast-header {
      background-color: $howden-highlight-light-color;
      color: $light-text;
    }
  }

  .mat-mdc-row>.mat-mdc-cell {
    white-space: pre-wrap;
  }

  &.table-bordered {
    border: 1px solid $dividers-light;
  }

  .mat-mdc-row:hover {
    opacity: 0.75;
  }

  .highlight {
    font-weight: bold;
    background-color: $howden-accent-light-color;

    mat-cell {
      font-size: 1.1em;
    }

    a {
      font-size: 1em;
    }

    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
    .mdc-checkbox__background {
      border-color: $dark-primary-text;
    }
  }

  .table-noresult-container {
    background: $light-bg-lighter-5;
    border-bottom: 1px solid $dividers-dark;

    .table-noresult {
      span {
        color: $howden-primary-light-color;
      }
    }
  }
}

.dark-theme {
  .mat-mdc-table {
    .mat-mdc-header-row>.mat-mdc-header-cell {
      color: $howden-accent-light-color;
      font-weight: 500;
    }

    .mat-mdc-row>.mat-mdc-cell {
      white-space: pre-wrap;
    }

    &.table-bordered {
      border: 1px solid $dividers-dark;
    }

    th.contrast-header {
      background-color: $howden-highlight-dark-color;
      color: $light-text;
    }

    .mat-mdc-row:hover {
      opacity: 0.75;
    }

    .highlight {
      font-weight: bold;
      background-color: white;

      .ellipsis-text {
        color: $dark-primary-text;
      }

      mat-cell {
        font-size: 1.1em;
        color: $dark-primary-text;
      }

      a {
        font-size: 1em;
      }

      /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
      .mdc-checkbox__background {
        border-color: $dark-primary-text;
      }
    }
  }

  .table-noresult-container {
    background: $dark-bg-lighter-10;
    border-bottom: 1px solid $dividers-light;

    .table-noresult {
      span {
        color: white;
      }
    }
  }
}

@media (max-width: $breakpoint-sm-size) {
  .table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;

    tr {
      td {
        white-space: nowrap;
      }
    }
  }
}

howden-table {
  width: 100%;

  &.small-font {
    .mat-mdc-header-row {
      min-height: 36px;

      .mat-mdc-header-cell {
        font-size: 0.9em !important;
      }
    }

    .mat-mdc-row {
      min-height: 36px;

      .mat-mdc-cell {
        font-size: 0.85em;
      }
    }
  }

  .table-container {
    overflow-x: auto;
    width: 100%;

    &.hasMaxHeight {
      overflow-y: auto;
      height: 100%;
    }

    .mat-mdc-table {
      .col-left-aligment {
        text-align: left;
        justify-content: flex-start;
      }

      .col-right-aligment {
        text-align: right;
        justify-content: flex-end;
      }

      .col-center-aligment {
        text-align: center;
        justify-content: center;
      }

      .small-cell {
        flex: 0 0 75px;
        max-width: 75px;

        // &:has(.mdc-checkbox) {
        //   padding-left: 0;
        // }
      }
    }
  }

  .table-noresult-container {
    display: flex;
    justify-content: center;
    flex-direction: column;

    .table-noresult {
      padding: 1em 0;
      width: 100%;
      text-align: center;
      margin-top: 1em;
      margin-bottom: 1em;

      span {
        font-size: 1.2em;
        font-weight: 500;
      }
    }
  }
}