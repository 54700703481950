html,
body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
body {
  -webkit-font-smoothing: antialiased;
}
