//////////////////////////////////////////////////////////////////////////////
// Angular material form fields
//////////////////////////////////////////////////////////////////////////////

.mat-mdc-form-field {
  &:not(.mat-mdc-paginator-page-size-select) {
    min-width: 100px;
    width: 100%;
  }

  mat-icon {
    padding-right: 0.25em;
  }
}

//////////////////////////////////////////////////////////////////////////////
// Angular material error control
//////////////////////////////////////////////////////////////////////////////

// https://github.com/angular/components/issues/27787
mat-error {
  font-size: 87.5%;

  &.mat-mdc-form-field-bottom-align:has(div) {
    &::before {
      display: none;
    }
  }
}

//////////////////////////////////////////////////////////////////////////////
// Howden decimal
//////////////////////////////////////////////////////////////////////////////

howden-decimal {
  width: 100%;
}