$spaceamounts: (0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100);

@mixin generate-margin-bottom() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .margin-bottom-#{$space} {
        margin-bottom: #{$space} !important;
      }
    } @else {
      .margin-bottom-#{$space} {
        margin-bottom: #{$space}px !important;
      }
    }
  }
}

@mixin generate-margin-top() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .margin-top-#{$space} {
        margin-top: #{$space} !important;
      }
    } @else {
      .margin-top-#{$space} {
        margin-top: #{$space}px !important;
      }
    }
  }
}

@mixin generate-margin-right() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .margin-right-#{$space} {
        margin-right: #{$space} !important;
      }
    } @else {
      .margin-right-#{$space} {
        margin-right: #{$space}px !important;
      }
    }
  }
}

@mixin generate-margin-left() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .margin-left-#{$space} {
        margin-left: #{$space} !important;
      }
    } @else {
      .margin-left-#{$space} {
        margin-left: #{$space}px !important;
      }
    }
  }
}

@mixin generate-margin() {
  @each $space in $spaceamounts {
    @if $space == 0 {
      .margin-#{$space} {
        margin: #{$space} #{$space} #{$space} #{$space} !important;
      }
    } @else {
      .margin-#{$space} {
        margin: #{$space}px #{$space}px #{$space}px #{$space}px !important;
      }
    }
  }
}

@include generate-margin-bottom();
@include generate-margin-top();
@include generate-margin-right();
@include generate-margin-left();
@include generate-margin();
