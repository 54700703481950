.light-theme {
  a {
    &:not(.mat-mdc-mini-fab) {
      color: $howden-highlight-light-color;
    }

    &:hover {
      color: $howden-filler-color !important;
    }
  }
}

.dark-theme {
  a {
    &:not(.mat-mdc-mini-fab) {
      color: $howden-primary-dark-color;
    }

    &:hover {
      color: $howden-filler-color !important;
    }
  }
}