$perHeight: (10, 20, 30, 40, 50, 60, 70, 80, 90, 100);

@mixin generate-max-height() {
  @each $height in $perHeight {
    .max-height-#{$height} {
      max-height: #{$height}vh !important;
      overflow-y: auto;
    }
  }
}

@include generate-max-height();
