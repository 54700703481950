.file-input {
  display: none;
}

howden-file-upload {
  position: relative;

  &.required::after {
    content: "*";
    font-size: 20px;
    position: absolute;
    right: 10px;
    top: 2px;
  }
}