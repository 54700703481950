@use '@angular/material' as mat;

howden-sidemenu {
  .mat-sidenav {
    overflow-y: auto;

    .mat-mdc-nav-list {
      .mat-mdc-list-item {
        .mat-mdc-list-item-unscoped-content {
          display: flex;
          line-height: 28px;
        }

        .mat-icon {
          margin-right: 0.5em;
          align-self: center;
        }

        [fill] {
          flex-grow: 1;
        }
      }
    }
  }
}

.dark-theme {
  howden-sidemenu {
    .mat-sidenav {
      background: $dark-background;
    }

    .mat-drawer {
      .mat-icon {
        color: mat.m2-get-color-from-palette($howden-accent-p, 500);
      }
    }
  }
}

.light-theme {
  howden-sidemenu {
    .mat-drawer {
      .mat-icon {
        color: mat.m2-get-color-from-palette($howden-primary-p, 500);
      }
    }
  }
}