howden-checkbox-list {
  width: 100%;
  .checkboxlist-select {
    flex-grow: 1;
  }
  .checkboxlist-button-container {
    min-width: 6em;
    .mat-icon {
      font-size: 2em;
    }
  }
}
