@use '@angular/material' as mat;

footer {
  font-size: smaller;
  font-weight: 500;
  height: 50px;
  padding: 10px;

  .footer-browsers {
    display: flex;
    justify-content: space-around;
    align-items: center;

    img {
      margin-left: 1em;
      scale: 0.75;
    }
  }

  a {
    color: #fff !important;

    &:link {
      color: #fff !important;
      text-decoration: none !important;
    }

    &:visited {
      color: #fff !important;
      text-decoration: none !important;
    }

    &:hover {
      text-decoration: none !important;
    }

    &:active {
      color: #fff !important;
      text-decoration: none !important;
    }
  }
}

.light-theme {
  footer {
    background: mat.m2-get-color-from-palette($howden-primary-p, 500);
    color: #fff;
  }
}

.dark-theme {
  footer {
    background: $dark-background;
    color: $light-text;
  }
}