@use '@angular/material' as mat;

.mat-mdc-dialog-title {
  &:has(.mat-icon) {
    padding-top: 24px;
  }
}

.howden-dialog-content {
  .mat-mdc-card {
    margin: 0;
    padding: 0;
    border: none;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
  }

  &>.mat-icon {
    margin-top: 36px;
    scale: 3;
    display: inline-block;
    align-self: center;
  }

  @media (-webkit-device-pixel-ratio: 1.5) {
    &>.mat-icon {
      scale: 2;
      margin-bottom: 0em;
      margin-top: 0em;
      display: inline-block;
    }
  }

  .title-text {
    @include mat.m2-typography-level($howden-typography-config, 'headline-6');
  }

  .subtitle-text {
    padding: var(--mat-dialog-headline-padding, 0 24px 9px);
  }

  .primary-text {
    font-size: 1.2em;
  }

  .secondary-text {
    font-style: italic;
  }

  .mat-mdc-dialog-actions {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.dark-theme {
  .mat-mdc-dialog-container {
    .label-value-container {
      label {
        opacity: .5;
        font-weight: 600;
      }
    }
  }

  .howden-dialog-content {
    .title-text {
      color: $howden-accent-dark-color;
    }
  }
}

.light-theme {
  .mat-mdc-dialog-container {
    .label-value-container {
      label {
        @include mat.m2-typography-level($howden-typography-config, 'body-2');
        color: mat.m2-get-color-from-palette($howden-primary-p, 500);
        font-weight: 600;
      }
    }
  }

  .howden-dialog-content {
    color: mat.m2-get-color-from-palette($howden-primary-p, 500);

    .title-text {
      color: mat.m2-get-color-from-palette($howden-primary-p, 500);
    }
  }
}