@use '@angular/material' as mat;

$howden-light-theme: mat.m2-define-light-theme((
  color: (
    primary: $howden-primary-p,
    accent: $howden-information-p,
    warn: $howden-secondary-warning-p
  ),
  density: 0
));

.light-theme {
  @include mat.all-component-themes($howden-light-theme);
}

body.light-theme {
  --mat-sidenav-content-text-color: $dark-primary-text;
  --mat-sidenav-container-text-color: $dark-primary-text;
  --mdc-list-list-item-label-text-color: $dark-primary-text;

  background-color: $light-background;
  color: $dark-primary-text;

  // main {
  //   background-color: rgba(61, 61, 61, 0.05);
  // }

  .mat-mdc-tooltip-surface {
    background-color: $howden-info-color;
  }

  .mat-mdc-form-field:not(.mat-form-field-invalid).mat-focused .mat-mdc-form-field-required-marker {
    color: mat.m2-get-color-from-palette($howden-primary-p, 500) !important;
  }
}
