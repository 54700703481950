.dark-theme {
  .spinner-container {
    background-color: rgba($light-background, 0.25);
  }
}

.light-theme {
  .spinner-container {
    background-color: rgba($dark-background, 0.25);
  }
}

.spinner-container {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10000;

  mat-progress-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
    width: 100px;
    height: 100px;
  }
}

.progress-bar-container {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
}
