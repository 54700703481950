.cursor-pointer {
  cursor: pointer !important;
}

.cursor-wait {
  cursor: wait !important;
}

.cursor-none {
  cursor: none !important;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-copy {
  cursor: copy !important;
}

.cursor-move {
  cursor: move !important;
}

.cursor-grab {
  cursor: grab !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-hand {
  cursor: hand !important;
}
